<template>
	<div class="europe-detail">
		<div class="europe-detaila">
			<div class="europe-detailb">
				<div class="europe-detailc">
					<!-- 一起飞国际机票网 -->
					<div class="europe-detailcc">一起飞国际机票网</div>
					<div class="europe-detailccc iconfont icon-chalv-xiangzuo"></div>
					<div class="europe-detailcc">欧铁</div>
					<div class="europe-detailccc iconfont icon-chalv-xiangzuo"></div>
					<div class="">中欧三地游通票</div>
					<!-- 一起飞国际机票网 -->
				</div>
				<div class="europe-detaild">
					<!-- 图片选择 -->
					<div class="europe-detaile">
						<div class="europe-detailf">
							<img src="../../assets/outair.png" class="europe-detailg">
						</div>
						<div class="europe-detailfff">
							<div class="europe-detailff" v-for="itme in [1,2,3]" :key="itme">
								<img src="../../assets/outair.png" class="europe-detailg">
							</div>
						</div>
					</div>
					<div class="europe-three">
						<!-- 中欧三地游通票 -->
						<div class="europe-threea">中欧三地游通票</div>
						<div class="europe-threeb">Central Europe Triangle</div>
						<div class="europe-threec">持有中欧三地游火车通票在有效期内可以搭乘:奥地利、匈牙利、捷克得国营城际火车。及享受相关各类优惠旅游产品。路线:
							维也纳-布拉格-布达佩斯-维也纳；维也纳-布拉格-萨尔茨堡-维也纳。反方向使用也可以。</div>
						<div class="europe-threed europe-threedd">
							<div class="europe-threee">已选国家: </div>
							<div class="europe-threee">法国</div>
							<div class="europe-threee">美国</div>
							<div class="">德国</div>
						</div>
						<div class="europe-threed">
							<div class="europe-threef">
								<div class="">价</div>
								<div class="">格:</div>
							</div>
							<div class="europe-threeg">
								<div class="europe-threega">¥</div>
								<div class="europe-threegb">361</div>
								<div class="europe-threega">起</div>
							</div>
						</div>
					</div>
				</div>

				<div class="europe-materials">
					<!-- 所需材料选项栏 -->
					<div class="europe-materialsa">
						<a href="#0" :class="['europe-materialsb',option1==0?'europe-materialsbb':'']"
							@click="option1=0">所需材料</a>
						<a href="#1" :class="['europe-materialsb',option1==1?'europe-materialsbb':'']"
							@click="option1=1">产品详情</a>
						<a href="#2" :class="['europe-materialsb',option1==2?'europe-materialsbb':'']"
							@click="option1=2">常见问题</a>
						<a href="#3" :class="['europe-materialsb',option1==3?'europe-materialsbb':'']"
							@click="option1=3">用户点评</a>
						<!-- <div class="" v-for="(itme,index) in option" :key="index"
							:class="[option1==index?'europe-materialsbb':'europe-materialsb']" @click="btn(index)">
							{{itme}}
						</div> -->
					</div>
					<div class="">
						<div class="europe-surface">
							<!-- 中欧三地游通票价目表 -->
							<a name="0" class="europe-surfacea">中欧三地游通票价目表</a>
							<div class="europe-surfaceb">
								<div class="europe-surfacec iconfont icon-03tanhao"></div>
								<div class="europe-surfacef">票价: </div>
								<div class="europe-surfacef">人民币/人</div>
								<div class="europe-surfacef">出票费:</div>
								<div class="europe-surfacef">12元/人/段</div>
								<div class="europe-surfaceh iconfont icon-03tanhao"></div>
								<div class="europe-surfacei">年龄规则</div>
							</div>
						</div>

						<div class="">
							<div class="europe-seat">
								<!-- 使用期限 -->
								<div class="europe-seata">
									<div class="europe-seatb">使用期限</div>
									<div class="europe-seatc">
										<div class="europe-seatd">座位等级</div>
										<el-select v-model="value" placeholder="请选择">
											<el-option v-for="item in options" :key="item.value" :label="item.label"
												:value="item.value" class="europe-seate">
											</el-option>
										</el-select>
										<!-- <div class="iconfont icon-chalv-xiangxia"></div> -->
									</div>
									<div class="europe-seatf">成人</div>
									<div class="europe-seatf">小孩</div>
									<div class="europe-seatf">青年</div>
									<div class="europe-seatf">老人</div>
									<div class="europe-seatf">2-5成人</div>
									<div class="europe-seatf">2-5儿童</div>
								</div>
							</div>

							<div class="europe-months">
								<div class="europe-month" v-for="itme in [1,2,3,4,5]" :key="itme">
									<div class="europe-montha">
										<div class="europe-monthb">1个月内任选两天</div>
									</div>
									<div class="europe-monthc">
										<div class="europe-monthe" v-for="itme in [1,2]" :key="itme">
											<div class="europe-monthf">
												<div class="europe-monthff">一等座</div>
											</div>
											<div class="" v-for="itme in [1,2,3,4,5,6]" :key="itme">
												<div class="europe-monthi">
													<div class="">¥</div>
													<div class="">361.00</div>
												</div>
											</div>
											<div class="europe-monthy">
												<div class="europe-monthyd">预订</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="europe-det">
								<a name="1" class="europe-deta">产品详情</a>
								<div class="europe-detb">
									<div class="europe-detc">适用国家: </div>
									<div class="">奥地利 比利时 保加利亚 克罗地亚 捷克共和国 丹麦 芬兰 法国 德国 希腊 匈牙利 意大利 卢森堡</div>
								</div>
								<div class="europe-detb">
									<div class="europe-detc">年龄限制: </div>
									<div class="europe-detb">
										<div class="europe-detc">青年: </div>
										<div class="">12-27岁</div>
									</div>
									<div class="europe-detb">
										<div class="europe-detc">成人: </div>
										<div class="">12-99岁</div>
									</div>
								</div>
								<div class="europe-detd">
									<div class="europe-dete">使用情况: </div>
									<div class="europe-detf">
										<div class="europe-detg">
											<div class="europe-detc">1.</div>
											<div class="">
												欧元报价，人民币支付，汇率将参考相关银行牌价，以出票之日我机构（含本网站）告知为准。
											</div>
										</div>
										<div class="europe-detg">
											<div class="europe-detc">2.</div>
											<div class="">
												如持有欧铁通票，乘搭所有的欧洲过夜火车(夜卧火车)以及部分国家的高速列车都需要提前预订卧铺/座位，这些预订是必须乘车前需要支付额外的预订费用，否则无法乘车。购买通票的同时，我司能提供查询和预订服务。提醒:
												提供给持通票预订位置数量有限，建议提前2-3个月做准备为佳。
											</div>
										</div>
										<div class="">
											<div class="europe-detg">
												<div class="europe-detc">3.</div>
												<div class="">
													乘客限制: 一下国家长期居留的人士不可以使用及购买此通票:
													<br>阿尔巴尼亚、安道尔、奥地利、白俄罗斯、比利时、波斯尼亚-黑塞哥维那、保加利亚、克罗地亚、捷克、塞浦路斯、丹麦、爱沙尼亚、法罗群岛、芬兰、马其顿、法国、德国、直布罗陀、希腊、匈牙利、冰岛、爱尔兰、意大利、拉脱维亚、列支敦士登、立陶宛、卢森堡、马其他、摩尔多瓦、摩纳哥、黑山、荷兰、挪威、波兰、葡萄牙、罗马尼亚、俄罗斯、圣马力诺、斯洛伐克、斯洛文尼亚、西班牙、瑞典、土耳其、乌克兰、英国、梵蒂冈。
												</div>
											</div>
										</div>

										<div class="europe-detg">
											<div class="europe-detc">4.</div>
											<div class="">
												2-5人同行: 2-5人将填开在同一张客票上，要求所有乘客在第一次使用时必须同时在场，此后结伴同行；儿童算为1为乘客
											</div>
										</div>

										<div class="europe-detg">
											<div class="europe-detc">5.</div>
											<div class="">
												19点规则:
												如持有活期通票乘客坐19点以后出发的直达过夜火车，在通票日期栏内填写第二天的日期且第二天必须在有效期内，第二天可继续乘坐火车；如持连续通票乘坐19点以后出发的直达火车，第二天必须要有效期内。
											</div>
										</div>

										<div class="europe-detg">
											<div class="europe-detc">6.</div>
											<div class="">
												完全未使用的通票可办理退票，相关购票、退票、改票等事宜，请以出票机构最终告知为准。如客票部分使用、损毁、丢失、被偷均不可退票。
											</div>
										</div>
										<div class="europe-detg">
											<div class="europe-detc">7.</div>
											<div class="">
												此报价为最终价格，购票时需另外支付4欧元/人/张出票费。
											</div>
										</div>
										<div class="europe-detg">
											<div class="europe-detc">8.</div>
											<div class="">
												本机构完全享有对于通票及其销售的定义、性质、用途、规则的解释权。
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="europe-especially">
								<div class="europe-especiallyb">特别优惠</div>
								<div class="" v-for="itme in [1,2]" :key="itme">
									<div class="europe-detb">
										<div class="europe-detc">奥地利</div>
										<div class="">AUSTRIA</div>
									</div>

									<div class="europe-especiallya" v-for="itme in [1,2,3,4,5,6]" :key="itme">
										<div class="europe-detc">1.</div>
										<div class="">博登湖之旅: 由5-10月，由BSB，SBS及？BB 经营的博登湖之旅为旅客提供半价折优惠。详情请参阅:
											www.bsb-online.com。博登湖之旅: 由5-10月，由BSB，SBS及？BB 经营的博登湖之旅为旅客提供半价折优惠。详情请参阅:
											www.bsb-online.com。</div>
									</div>
								</div>
							</div>

							<div class="europe-familiar">
								<a name="2" class="europe-familiarb">常见问题</a>
								<div class="" v-for="itme in [1,2,3,4,5,6]" :key="itme">
									<div class="europe-familiara">
										<div class="europe-detc">1.</div>
										<div class="">如果我有通票，时所有的火车都可以乘坐的吗？</div>
									</div>
									<div class="europe-familiarc">
										<div class="europe-familiard">
											答:欧洲火车绝大部分为国有铁路，持有通票可以乘坐，但是欧洲各地也有一些属于私人经营的火车或登山铁路、缆车； 列如:
											瑞士的铁力士、少女峰等，持通票不可免费乘坐，但是会享受一定优惠
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="">
							<div class="europe-critica">
								<a name="3" class="europe-criticb">
									<el-rate v-model="value0" disabled show-score text-color="#ff9900"
										score-template="{value}">
									</el-rate>
									<div class="europe-criticc">分</div>
								</a>

								<div class="">来自520条评论</div>
							</div>

							<div class="europe-critis" v-for="itme in [1,2,3,4,5,6]" :key="itme">
								<div class="europe-critic">
									<div class="europe-criticd">
										<div class="europe-critice">
											<div class="europe-criticf">
												<div class="europe-criticg">
													<el-avatar icon="el-icon-user-solid"></el-avatar>
												</div>
												<div class="">王楚钦</div>
											</div>

											<div class="europe-critich">
												<div class="europe-critici">
													<div class="europe-criticz">
														<el-rate v-model="value1" :colors="colors"></el-rate>
														<div class="europe-critick">个人旅行</div>
														<div class="europe-critick">情侣出行</div>
													</div>
													<div class="">2018年8月23日</div>
												</div>
												<div class="europe-critico">出签速度非常块，提交材料非常方便，顾问服务费常周到。十分满意下次继续</div>
											</div>
										</div>
									</div>
								</div>

								<div class="">
									<div class="europe-criticp">
										<div class="europe-criticq" v-for="item in [1,2,3,4,5]" :key="item">
											<el-avatar shape="" :size="120" :src="url"></el-avatar>
										</div>
									</div>
								</div>

								<div class="europe-criticr">
									<div class="">来自520条评论</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var thar;
	export default {
		data() {
			return {
				option1: 0,
				// option: ['所需材料', '产品详情', '常见问题', '用户点评'],
				options: [{
					value: '选项1',
					label: '不限'
				}, {
					value: '选项2',
					label: '一等座'
				}, {
					value: '选项3',
					label: '二等座'
				}],
				value0: 4.4,
				value: '',
				circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
				value1: null,
				colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
				url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
			}
		},
		methods: {
			// btn(index) {
			// 	this.option1 = index
			// 	console.log(index)
			// }
		}
	}
</script>

<style lang="scss">
	@import '../../style/Europe/detail.scss';
</style>
